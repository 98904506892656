






























import Vue from 'vue'
import RecipeApi from '../services/RecipeApi'
import CocktailApi from '../services/CocktailApi'
import WineApi from '../services/WineApi'

export default Vue.extend({
  name: 'MostRated',

  data () {
    return {
      title: {
        recipe: 'Most Rated Recipes',
        wine: 'Most Rated Wines',
        cocktail: 'Most Rated Cocktails'
      },
      showMagicItem: true,
      showResult: false,
      hasMargin: true,
      type: this.$route.path.split('/')[1],
      adapters: {
        'recipe': RecipeApi,
        'cocktail': CocktailApi,
        'wine': WineApi
      },
      content: {
        result: [],
        loading: false as boolean
      },
      resultData: []
    }
  },

  mounted () {
    this.adapters[this.type].model = this.content
    this.adapters[this.type].getMostRated()
  },

  methods: {
    hideResult: function () {
      this.showResult = false
      this.showMagicItem = true
      this.hasMargin = true
    },

    setResultData: function (data) {
      var self = this
      this.resultData = data === null ? [] : data
      setTimeout(function () {
        self.showResult = true
        self.showMagicItem = false
        self.hasMargin = false
      }, 2500)
    }
  },

  watch: {
    $route () {
      this.type = this.$route.path.split('/')[1]
      this.adapters[this.type].model = this.content
      this.adapters[this.type].getMostRated()
    },
    'content.loading' (loading) {
      if (!loading) {
        this.resultData = this.content.result
        this.showResult = true
        // this.$parent.setResultData(this.content.result)
      }
    }
  },

  metaInfo () {
    return {
      title: 'MostRated',

      meta: [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: 'MostRated' },
        { vmid: 'description', name: 'description', content: 'Gimme Recipe - A recipe generator written javascript' },
        { vmid: 'ogdescription', property: 'og:description', content: 'Gimme Recipe - A recipe generator written javascript' }
      ]
    }
  }
})
